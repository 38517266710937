.socialIcons {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
}

.blockedContent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.navlist {
  list-style: none;
  display: inline;
  font-family: "'Dosis', sans-serif";
  padding: 0;
}

.dark {
  color: #352e34;
}
.light {
  color: #c7e6fc;
}

.navigation {
  bottom: 0;
  position: absolute;
  text-align: center;
  padding-bottom: 10px;
  width: 100%;
}

.navlist > li {
  display: inline;
}
.navlist > li:hover {
  cursor: pointer;
}
.navlist > li > a:hover {
  border-bottom: 1px solid #2196f3;
}

.navlist > li::before {
  content: '-';
  padding: 0 12px 0 9px;
}

#pBlock {
  margin-left: auto;
  margin-right: auto;
  background-image: url('../images/texture.png');
}

#pBlock > p {
  padding: 0px 9px;
}

#pBlock > p > a {
  color: #2196f3
}

#projectsDiv > p > a {
  color: #2196f3
}

.firstItem::before {
  content: '' !important;
  padding: 0 12px 0 0px !important;
}

.tile {
  position: relative;
  overflow: hidden;
}

.home {
  background-color: #C7E6FC;
}

.about {
  background-color: #352e34;
}

.shape {
  content:'';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
}

.blue {
  border-top: solid 30px #c7e6fc;
}
.brown {
  border-top: solid 30px #352e34;
}
#contact {
  background-image: url('../images/texture.png');
}