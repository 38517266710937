body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #C7E6FC;
  min-width:400px;
  width: auto !important; 
  width:400px;  
}

.wrapper {
  margin: 0 auto;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in;
}

.App-link {
  color: #61dafb;
}

.errorPageText {
  text-align: center;
  color: #352e34;
}

.errorPageText > a {
  color: #2196f3;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.catWindow {
  text-align: center;
}

.weatherInfo {
  font-size: 12px;
  padding-top: 3em;
  font-family: "'Raleway', sans-serif";
  color: #352e34
}

.windowFrame {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -200px;
}

.windowView {
  width: 315px;
  height: 315px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -167.5px;
  margin-left: -157.5px;
}

.windowView.day {
  background-image: -webkit-radial-gradient(at top, #bdddf9, #91bae0);
  background-image: radial-gradient(at top, #bdddf9, #91bae0); /* FF3.6+ */ /* FF3.6+ */ /* Opera 11.10+ */
  background-image: -ms-radial-gradient(at top, #bdddf9, #91bae0); /* IE10+ */
}
.windowView.dayRain {
  background-image: -webkit-radial-gradient(at top, #b8b9d8, #9899ad);
  background-image: radial-gradient(at top, #b8b9d8, #9899ad); /* FF3.6+ */ /* FF3.6+ */ /* Opera 11.10+ */
  background-image: -ms-radial-gradient(at top, #b8b9d8, #9899ad); /* IE10+ */
}

.windowView.sunrise {
  background-image: -webkit-linear-gradient(top left, #e08594, #ffd596);
  background-image: linear-gradient(to bottom right, #e08594, #ffd596);
  background-image: -ms-linear-gradient(to bottom right, #e08594, #ffd596);
}

.windowView.sunset {
  background-image: -webkit-linear-gradient(top right, #ffcb32, yellow);
  background-image: linear-gradient(to bottom left, #ffcb32, yellow);
  background-image: -ms-linear-gradient(to bottom left, #ffcb32, yellow);
}

.windowView.night {
  background-image: -webkit-radial-gradient(at top, #2454c1, #17377f);
  background-image: radial-gradient(at top, #2454c1, #17377f);
  background-image: -ms-radial-gradient(at top, #2454c1, #17377f);
}

.windowView.nightRain {
  background-image: -webkit-radial-gradient(at top, #1e2528, #1e2e30);
  background-image: radial-gradient(at top, #1e2528, #1e2e30); /* FF3.6+ */ /* Opera 11.10+ */
  background-image: -ms-radial-gradient(at top, #1e2528, #1e2e30); /* IE10+ */
}

.cloud {
  will-change: transform;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.c1{
  -webkit-animation: move 35s infinite linear;
          animation: move 35s infinite linear;
  -webkit-animation-delay: -12s;
          animation-delay: -12s;
}

.c2 {
  -webkit-animation: move 30s infinite linear;
          animation: move 30s infinite linear;
  -webkit-animation-delay: -25s;
          animation-delay: -25s;
}
.c3 {
  -webkit-animation: move 33s infinite linear;
          animation: move 33s infinite linear;
  -webkit-animation-delay: -21s;
          animation-delay: -21s;
}
.c4 {
  -webkit-animation: move 37s infinite linear;
          animation: move 37s infinite linear;
  -webkit-animation-delay: -17s;
          animation-delay: -17s;
}
.c5 {
  -webkit-animation: move 39s infinite linear;
          animation: move 39s infinite linear;
  -webkit-animation-delay: -24s;
          animation-delay: -24s;
}
.c6 {
  -webkit-animation: move 45s infinite linear;
          animation: move 45s infinite linear;
  -webkit-animation-delay: -20s;
          animation-delay: -20s;
}
.c7 {
  -webkit-animation: move 60s infinite linear;
          animation: move 60s infinite linear;
  -webkit-animation-delay: -29s;
          animation-delay: -29s;
}
.snow {
	will-change: transform;
	-webkit-transform: translateY(-100%);
	        transform: translateY(-100%);
}
.s-top-layer {
  -webkit-animation: fall 22.5s infinite linear;
          animation: fall 22.5s infinite linear; 
  -webkit-animation-delay: -10s; 
          animation-delay: -10s;
}

.s-bottom-layer {
  -webkit-animation: fall 45s infinite linear;
          animation: fall 45s infinite linear;
  -webkit-animation-delay: -20s;
          animation-delay: -20s;
}
.s-middle-layer {
  -webkit-animation: fall 33s infinite linear;
          animation: fall 33s infinite linear;
}

.rain {
	will-change: transform;
	-webkit-transform: translateY(-100%);
	        transform: translateY(-100%);
}

.r-top-layer {
  -webkit-animation: fall 1.7s infinite linear;
          animation: fall 1.7s infinite linear; 
}

.r-bottom-layer {
  -webkit-animation: fall 1.2s infinite linear;
          animation: fall 1.2s infinite linear;
}

.r-middle-layer {
  -webkit-animation: fall 1.5s infinite linear;
          animation: fall 1.5s infinite linear;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.second {
  -webkit-animation: fall 1s infinite linear;
          animation: fall 1s infinite linear;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

@-webkit-keyframes fall {
  from {
    -webkit-transform: translateY(-370px);
            transform: translateY(-370px);
  }
  to {
    -webkit-transform: translateY(370px);
            transform: translateY(370px);
  }
}

@keyframes fall {
  from {
    -webkit-transform: translateY(-370px);
            transform: translateY(-370px);
  }
  to {
    -webkit-transform: translateY(370px);
            transform: translateY(370px);
  }
}

@-webkit-keyframes move {
  from {
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px);
  }
  to {
    -webkit-transform: translateX(400px);
            transform: translateX(400px);
  }
}

@keyframes move {
  from {
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px);
  }
  to {
    -webkit-transform: translateX(400px);
            transform: translateX(400px);
  }
}
.socialIcons {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
}

.blockedContent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.navlist {
  list-style: none;
  display: inline;
  font-family: "'Dosis', sans-serif";
  padding: 0;
}

.dark {
  color: #352e34;
}
.light {
  color: #c7e6fc;
}

.navigation {
  bottom: 0;
  position: absolute;
  text-align: center;
  padding-bottom: 10px;
  width: 100%;
}

.navlist > li {
  display: inline;
}
.navlist > li:hover {
  cursor: pointer;
}
.navlist > li > a:hover {
  border-bottom: 1px solid #2196f3;
}

.navlist > li::before {
  content: '-';
  padding: 0 12px 0 9px;
}

#pBlock {
  margin-left: auto;
  margin-right: auto;
  background-image: url(/static/media/texture.bd10a830.png);
}

#pBlock > p {
  padding: 0px 9px;
}

#pBlock > p > a {
  color: #2196f3
}

#projectsDiv > p > a {
  color: #2196f3
}

.firstItem::before {
  content: '' !important;
  padding: 0 12px 0 0px !important;
}

.tile {
  position: relative;
  overflow: hidden;
}

.home {
  background-color: #C7E6FC;
}

.about {
  background-color: #352e34;
}

.shape {
  content:'';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
}

.blue {
  border-top: solid 30px #c7e6fc;
}
.brown {
  border-top: solid 30px #352e34;
}
#contact {
  background-image: url(/static/media/texture.bd10a830.png);
}
